import React from 'react';
import {BallTriangle} from 'react-loader-spinner'; 
import styles from './Loading.module.css'
//npm i react-loader-spinner
//documentation : https://mhnpd.github.io/react-loader-spinner/docs/intro/

const Loading = ({ onloading, loading_object_name }) => {
  return (
    <div className={styles.Loading}>
    {onloading ? 
        <div className={styles.LoadingIconWrapper}>
          <div className={styles.LoadingIcon}>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#F39800"
            ariaLabel="ball-triangle-loading"
            wrapperClass=""
            wrapperStyle=""
            visible={true}
          />
          </div>
          <h2>{loading_object_name}をロード中...</h2>
        </div> : ""}
  </div>
  );
};

export default Loading;