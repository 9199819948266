import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import styles from './CommonHeader.module.css'; 

import logoIcon from './addlibLogo_2.png';
import OptionIcon from './option.png';
import MypageIcon from './watashi_white.png';
import MagnifyingGlassIcon from './searchIcon_orange.png';
import FaderIcon from './filterIcon_orange_2.png'; // フェーダーアイコン
import UploadIcon from './uploadIcon_white_2.png';
import BandIcon from './band_white.png';
import SearchFilter from './SearchFilter';
import Button from '../Button/Button';


/*

使用する際はヘッダー以外を<main></main>で囲うこと！

*/
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const CommonHeader = ({setData, setLoadingScores, initSearchTerm="", savedQueryFields, resetData}) => {

    
    //ページ遷移関係のおまじない
    const location = useLocation();
    const navigate = useNavigate();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            //検索を実行
            simpleSearch();
        }
    }
 
    //API関係
    //アレンジャー一覧

    const [useritems, setUseritems] = useState([]);
    const getUserList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/user/userlist`, requestOptions);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                data.sort((a, b) => {
                    if (a.ki.N === b.ki.N) {
                        // xが等しい場合はyで比較
                        return a.nickname.S.localeCompare(b.nickname.S);
                    }
                    // xで比較
                    return a.ki.N - b.ki.N;
                });
                setUseritems(data); // パースしたデータを状態にセット
                
            } else {
                console.error('Failed to get user item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    }
    //バンド一覧取得
    const [banditems, setBanditems] = useState([]);
    const getBandList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/band/bandlist`, requestOptions);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                setBanditems(data); // パースしたデータを状態にセット
                //console.error('band Item successfully got.');
            } else {
                console.error('Failed to get band item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    }
    //タグ一覧
    const [allTagsOption, setAllTagsOption] = useState([]); //[{label: value:}]
    const [allTags, setAllTags] = useState([]);
    const getTagList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/tags`, requestOptions);
            if (response.status === 200) {
                
                const data = await response.json();
                // タグの内容を取得し、それをlabelとvalueを持つオブジェクトに変換し、ソート
                
                const tagOptions = data.map(item => ({ label: item.content, value: item.tagId }));
                tagOptions.sort((a, b) => a.label.localeCompare(b.label)); // ラベルに基づいてソート
                setAllTagsOption(tagOptions);
                setAllTags(data);
            } else {
                console.error(response.status, 'Failed to tag band item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    };

    //アーティスト名一覧
    const [allArtists, setAllArtists] = useState([]);
    const getArtistsList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/scores/artists`, requestOptions);
            if (response.status === 200) {
                
                const data = await response.json();
                // 取得したアーティスト名の配列をステートに設定
                //console.log("artists:", data);
                setAllArtists(data);
            } else {
                console.error('Failed to tag artists item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    };

    //詳細検索を開くパターン
    const [showModal, setShowModal] = useState(false);
    // モーダルを表示する関数
    const handleShow = () => setShowModal(true);
    // モーダルを非表示にする関数
    const handleClose = () => setShowModal(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    //検索条件の初期値
    const queryFieldsInit = {
        s_score_name : "", //曲名
        s_artist_name: "", //アーティスト名
        s_difficulty: {},   //難易度　1(入門)~5(難しい)
        s_duration_min: 1, //曲尺の最低値（単位：秒）
        s_duration_max: 99999999, //曲尺の最大値 (単位：秒)
        s_is_changeLead: {}, //リード回し。 -1->選択なし true->リード回し false->リード固定
        s_tempo : -1, //テンポ。-1=指定なし、1=ローテンポ、2=ミドルテンポ、3=アップテンポ
        s_part_male_n : -1, //男声パート数
        s_part_female_n: -1,//女声パート数
        s_have_vp : {},//VP記載　
        s_need_vp : {},//VP想定　
        s_is_zentaikyoku: {}, //全体曲フラグ
        s_arranger_id: "", //アレンジャーID
        s_band_id: "",   //演奏バンドID （複数指定禁止）
        s_tag_id: "", //タグID（複数指定禁止）
    };

    //検索対象属性をここで保持
    const [queryFields, setQueryFields] = useState(queryFieldsInit); 
    //検索対象属性の値の変更
    const setQuery = (key, value) => {
        setQueryFields(prevState => ({...prevState, [key]: value}));
    };

    //検索ボタンを押されたときの挙動
    const handleSearchSubmit = async () => {
        //console.log("setLoadingScores:",setLoadingScores);
        if (typeof resetData === 'function'){
            await resetData();
        }
        if (location.pathname === '/search' && setData ) {
            setData([]);
            if(typeof setLoadingScores === 'function'){
                setLoadingScores(true);
                //console.log("setLoadingScores called");
            }else{
                console.error("setLoadingScores is undefined");
            }
        }else if (location.pathname === '/search' && typeof setLoadingScores === 'function' ){
            setLoadingScores(true);
            //console.log("setLoadingScores called");
        } else {
            console.warn("setLoadingScores is not defined or not a function");
        }
        //alert(JSON.stringify(queryFields))
        //apiに投げる用のクエリ定義
        const queries = [];
        //検索クエリをぶち込む
        //Operator 0: =, 1: < , 2: >, 3: begins_with
        //曲名
        queries.push({
            attribute: "score_name",
            operator: "3", //begins_with
            value: queryFields.s_score_name
        });
        //アーティスト名
        queries.push({
            attribute: "artist_name",
            operator: "3", //begins_with
            value: queryFields.s_artist_name
        });
        //難易度
        queries.push({
            attribute: "difficulty",
            operator: "0", //=
            value: queryFields.s_difficulty
        });
        //曲尺
        if(queryFields.s_duration_max && queryFields.s_duration_max >= 0){
            queries.push({
                attribute: "duration",
                operator: "1", //<
                value: queryFields.s_duration_max
            });
        }
        
        if(queryFields.s_duration_min && queryFields.s_duration_min >= 0){
            queries.push({
                attribute: "duration",
                operator: "2", //>
                value: queryFields.s_duration_min
            });
        }
        
        //テンポ
        if(queryFields.s_tempo && queryFields.s_tempo.length > 2){ //ローテンポとか
            queries.push({
                attribute: "tempo_str",
                operator: "0", //=
                value: queryFields.s_tempo
            });
        }
    

        //パート制約
        if(queryFields.s_is_zentaikyoku){
            if(queryFields.s_is_zentaikyoku["true"]){
                //全体曲なら男声パートがどうこうの指定は無視
                //全体曲フラグ
                queries.push({
                    attribute: "is_zentaikyoku",
                    operator: "0", // =
                    value: queryFields.s_is_zentaikyoku
                });
            }else{
                //パート制約系
                //初期値でなければ（0はヤロバンであり得るので含める）
                if(queryFields.s_part_male_n >= 0){
                    queries.push({
                        attribute: "part_male",
                        operator: "0", // = 
                        value: queryFields.s_part_male_n
                    });
                }
                if(queryFields.s_part_female_n >= 0){
                    queries.push({
                        attribute: "part_female",
                        operator: "0", // = 
                        value: queryFields.s_part_female_n
                    });
                }
            }
        }
        //VP記載
        //{"true": true }とか{"true": false, "false":true }とか来る
        queries.push({
            attribute: "have_vp",
            operator: "0", // =
            value: queryFields.s_have_vp
        });
        //VP想定
        queries.push({
            attribute: "need_vp",
            operator: "0", // =
            value: queryFields.s_need_vp
        });

        //リード回し
        queries.push({
            attribute: "is_changeLead",
            operator: "0", // =
            value: queryFields.s_is_changeLead
        });
        
        //API投げ(1)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ queries })
        };
        try {
            const promises = []; //属性での検索、アレンジャーの検索、演奏バンドの検索の積集合を取るが、それぞれ非同期でできる
            //console.log(requestOptions);
            const fetch_s_fields = fetch(`${API_PREFIX}/api/scores/search`, requestOptions); //なんでpostなんだこれ
            promises.push(fetch_s_fields);
            //デバッグのため一度コメントアウト
            
            if(queryFields.s_arranger_id.length > 0){
                //console.error(`${API_PREFIX}/api/arranged/userid/${queryFields.s_arranger_id}/scores`);
                const fetch_s_arranger = fetch(`${API_PREFIX}/api/arranged/user/${queryFields.s_arranger_id}/scores`, {method: 'GET'});
                promises.push(fetch_s_arranger);
            }
            if(queryFields.s_band_id.length > 0){
                const fetch_s_band = fetch(`${API_PREFIX}/api/performed/band/${queryFields.s_band_id}/scores`, {method: 'GET'});
                promises.push(fetch_s_band);
            }
            if(queryFields.s_tag_id.length > 0){
                const fetch_s_tag = fetch(`${API_PREFIX}/api/tag/${queryFields.s_tag_id}/scores`, {method: 'GET'});
                promises.push(fetch_s_tag);
            }
            
            const results = await Promise.all(promises);
            const resultsJson = await Promise.all(results.map(result => result.json()));
            let intersection;
            // 全てのAPIからの応答が正常かを確認し、データを処理
            if (results.every(response => response.status === 200)) {
                // IDの積集合を計算
                // レスポンスから配列を取得し、IDを抽出
                //console.error(JSON.stringify(resultsJson));
                //const ids = resultsJson.map(item => item.id);
                //const idsSet = new Set(ids);
                const idsSets = resultsJson.map(data => new Set(data)); // Set(data.map(item => item.id)));
                //TODO アレンジャーのみの検索でもフィールドでの検索が走るので空配列と積になる
                if (idsSets.length > 1) {
                    //console.error(idsSets.length);
                    intersection = idsSets.reduce((a, b) => new Set([...a].filter(id => b.has(id))));
                } else {
                    //console.error(idsSets[0]);
                    intersection = idsSets[0] || new Set(); // セットが1つだけの場合、または何もない場合
                }
        
                // Setを配列に変換
                const intersectionArray = [...intersection];
                //if (location.pathname === '/search' && setData ) {
                    // 既に一覧ページにいる場合
                //    setData(intersectionArray); //一覧ページのメンバ変数に対して
                //    //console.log(intersectionArray);
                    /*
                    if (typeof setLoadingScores === 'function') {
                        setLoadingScores(false);
                    } else {
                        console.warn("setLoadingScores is not defined or not a function");
                    }*/
                //} else {
                    // 他から一覧ページに遷移する場合、Linkのstateを使ってデータを渡す
                    //console.log("intersectionArray : ",intersectionArray);
                    /*
                    if (typeof setLoadingScores === 'function') {
                        setLoadingScores(false);
                    } else {
                        console.warn("setLoadingScores is not defined or not a function");
                    }
                    */
                    navigate('/search', { state: { intersectionArray:intersectionArray, queryFields:queryFields, searchTerm:""} , replace: false});
                //}
                
            }else{
                console.error('response not ok!');
            }

        } catch (error) {
            console.error('There was an error!', error);
        }
        setShowModal(false);
        //setQueryFields(queryFieldsInit); //指定した状況をリセット　TODO：リセットでなく保持
    };
    
    //詳細検索を開かないパターン
    const [searchTerm, setSearchTerm] = useState("");
    const simpleSearch = async() => {
        //console.log("simpleSearch Started with value:",searchTerm);
        //入力されたのは曲名扱い
        //apiに投げる用のクエリ定義

        //setLoadingScoresの操作
        //console.log("setLoadingScores:",setLoadingScores);
        if (typeof resetData === 'function'){
            await resetData();
        }
        if (location.pathname === '/search' && setData ) {
            setData([]);
            if(typeof setLoadingScores === 'function'){
                setLoadingScores(true);
                //console.log("setLoadingScores called");
            }else{
                console.error("setLoadingScores is undefined");
            }
        }else if (location.pathname === '/search' && typeof setLoadingScores === 'function' ){
            setLoadingScores(true);
            //console.log("setLoadingScores called");
        } else {
            console.warn("setLoadingScores is not defined or not a function");
        }

        const queries = [];
        //検索クエリをぶち込む
        //Operator 0: =, 1: < , 2: >, 3: begins_with
        //曲名
        queries.push({
            attribute: "score_name",
            operator: "3", //begins_with
            value: searchTerm
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ queries })
        };
        try {
            const promises = [];
            const fetch_s_fields = fetch(`${API_PREFIX}/api/scores/search`, requestOptions); //なんでpostなんだこれ
            promises.push(fetch_s_fields);
        
            const results = await Promise.all(promises);
            const resultsJson = await Promise.all(results.map(result => result.json()));
            let intersection;
            // 全てのAPIからの応答が正常かを確認し、データを処理
            if (results.every(response => response.status === 200)) {
                // IDの積集合を計算
                // レスポンスから配列を取得し、IDを抽出
                const idsSets = resultsJson.map(data => new Set(data));
                if (idsSets.length > 1) {
                    //こっちには行かないはずだが
                    intersection = idsSets.reduce((a, b) => new Set([...a].filter(id => b.has(id))));
                } else {
                    intersection = idsSets[0] || new Set(); // セットが1つだけの場合、または何もない場合
                }
        
                // Setを配列に変換
                const intersectionArray = [...intersection];
                //console.log("intersectionArray:",intersectionArray);
                //console.log(`location.pathname:${location.pathname}`)
                //if (location.pathname === '/search' && setData) {
                    // 既に一覧ページにいる場合
                    //console.log(setData);
                //    setData(intersectionArray); //一覧ページのメンバ変数に対して
                //} else {
                    // 他から一覧ページに遷移する場合、Linkのstateを使ってデータを渡す
                    //console.log("intersectionArray:",intersectionArray);
                    navigate('/search', { state: { intersectionArray:intersectionArray , searchTerm:searchTerm }, replace: false });
                //}
            }else{
                console.error('response not ok!');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        setShowModal(false);
    };

    //詳細検索内でのアレンジャー等のドロップダウン選択時の挙動
    const arrangerChange = (value) => {
        setQuery("s_arranger_id", value);
    };
    const bandChange = (value) => {
        setQuery("s_band_id", value);
    }
    const tagChanged = (value) => {
        setQuery("s_tag_id", value);
    };

    useEffect(() => {
        getUserList();
        getBandList();
        getTagList();
        getArtistsList();
    },[]);
    useEffect(() => {
        // ページ読み込み時
        setSearchTerm(initSearchTerm);
        if (savedQueryFields) {
            //console.log(`savedQueryFields adopted: ${savedQueryFields}`);
            setQueryFields(savedQueryFields);
        }
    }, [initSearchTerm, savedQueryFields]);
    //useEffect(() => {
        //console.log(`setData=${setData}, setLoadingScores=${setLoadingScores}, initSearchTerm=${initSearchTerm}, savedQueryFields=${savedQueryFields}`);
    //}, [setData, setLoadingScores, initSearchTerm, savedQueryFields])
    
    return (
        <div>
        <div className={styles.header}>

            <div className={styles.logoImage}>
            <Link to="/home">
                <img src={logoIcon} alt="ロゴ" className={styles.logoImage}/>
            </Link>
            </div>
            <div className={styles.IconsContainer}>
            <div className={styles.IconImage}>
                <Link to="/score_registration" className={styles.iconLink}>
                <img src={UploadIcon} alt="楽譜登録" />
                </Link>
                <Link to="/band_admin" className={styles.iconLink}>
                <img src={BandIcon} alt="バンド情報登録" />
                </Link>
                <Link to="/mypage" className={styles.iconLink}>
                <img src={MypageIcon} alt="マイページ" />
                </Link>
            </div>
            </div>
            <div className={styles.SearchBarContainer}>
            <img src={MagnifyingGlassIcon} alt="検索" className={styles.iconSearchbar} onClick={simpleSearch} style={{ cursor: 'pointer' }} />
            {/*
            <Link to="/search" className={styles.iconLink}>
                <img  src={MagnifyingGlassIcon} alt="検索" />
            </Link>
            */}
                <input
                className={styles.SearchBarInput}
                type="text"
                placeholder={" 曲名で探す"}
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleEnterKeyPress}
                />
            <div className={styles.iconSearchbar}>
                <img className={styles.SearchBarToggle} src={FaderIcon} alt="詳細検索" onClick={toggleModal} />
            </div>
        </div>
        </div>
        {showModal && (
        <div className={styles.overlay} onClick={handleClose}>
            <div className={styles.Modal} onClick={(e) => e.stopPropagation()}>
                <SearchFilter 
                closeModal={handleClose} 
                queryFields={queryFields} 
                queryFieldsInitialize={() => setQueryFields(queryFieldsInit)}
                setQuery={setQuery} 
                setData={setData} 
                setLoadingScores={setLoadingScores}
                useritems={useritems}
                useritemChange={arrangerChange}
                banditems={banditems}
                banditemChange={bandChange}
                tagitems={allTags}
                tagChange={tagChanged}
                artist_opts={allArtists}
                handleSearchSubmit={handleSearchSubmit}/>
            </div>
            <button className={styles.closeButton} onClick={handleClose}>×</button>
            <div class={styles.nonScroll}></div>{/*非スクロール用要素*/}
            
        </div>
        )}
    {/**
    <div>デバッグ用</div>
    <div>
    <p>曲名: {queryFields.s_score_name}</p>
    <p>アーティスト名: {queryFields.s_artist_name}</p>
    <p>難易度: {JSON.stringify(queryFields.s_difficulty)} （1:入門〜5:難しい）</p>
    <p>曲尺の最低値: {queryFields.s_duration_min} 秒</p>
    <p>曲尺の最大値: {queryFields.s_duration_max} 秒</p>
    <p>テンポ：{queryFields.s_tempo}</p>
    <p>男声パート数: {queryFields.s_part_male_n}</p>
    <p>女声パート数: {queryFields.s_part_female_n}</p>
    <p>VP記載: {JSON.stringify(queryFields.s_have_vp)}</p>
    <p>VP想定: {JSON.stringify(queryFields.s_need_vp)}</p>
    <p>全体曲フラグ: {JSON.stringify(queryFields.s_is_zentaikyoku)}</p>
    <p>アレンジャーID: {queryFields.s_arranger_id}</p>
    <p>演奏バンドID: {queryFields.s_band_id}</p>
    </div>
    */}
    </div>
    );
    };

export default CommonHeader;

